
import { defineComponent, onMounted, onBeforeMount } from "vue";
import ToolbarFilter from "@/components/toolbar/ToolbarFilter.vue";
import ToolbarActions from "@/components/toolbar/ToolbarActions.vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import useComponentv2 from '@/composables/v2/useComponent';
import Table from '@/components/tables/v2/Table.vue';
import ModalFlow from "@/components/modals/default/v2/ModalShipmentLinesFlow.vue";
import Modal from "@/components/modals/default/v2/Modal.vue";
import Icon from '@/components/icons/Icon.vue';

export default defineComponent({
  name: '',
  components: {
      ToolbarFilter,
      ToolbarActions,
      Table,
      Icon,
      ModalFlow,
      Modal,
  },
    props: {
      currentId: String
    },
    setup (props, { emit }) {
        const { initComponent, componentData, componentRegisterId, componentLoading, updateComponent, submitButtonComponent, submitLoadingComponent, saveComponent, componentStatus, reloadComponent, test, renderModal, renderAmount, renderId, reloadAmount } = useComponentv2();

    initComponent({
      componentName: "ShipmentsShipmentLinesLinesTable", 
      displayMethod: "existing", /* new or existing */
      componentType: "table", /* form or table */
      componentTypeActions: { init: true, actions: [
        { type: "delete", label: "Delete", icon: { name: "Delete", color: 'danger' }, visibility: { state: false, maxSelected: null }, dispatch: { endpoint: "SHIPMENT_LINE_DELETE", reload: false, modal: { init: false, componentName: "", displayMethod: "", componentType: "" }, payloadConfig: { linesKey: "sale_lines", idKey: "return_id" } } },
      ]},
      dispatchActions: { init: "SHIPMENT_LINES", save: "SHIPMENT_LINE_EDIT", params: { init: false, key: "", value: ""} },
      componentReload: true,
      componentRedirect: { init: false, data: "", path: ""},
      currentData: "allShipmentLines",
      componentFilter: { init: false, get: "" },
      componentToolbar: { init: true, filter: false, actions: { init: true, title: "Lines", menu: [ { label: "Create", target: "#es_modal_shipments_shipment_lines_create" } ] } },
      currentId: props.currentId
    });

    onMounted(() => {
        MenuComponent.reinitialization();
    })

    return {
      componentData,
      componentRegisterId,
      componentLoading,
      componentStatus,
      updateComponent,
      submitButtonComponent,
      submitLoadingComponent,
      saveComponent,
      reloadComponent,
      test,
      renderModal,
      renderId,
      renderAmount,
      reloadAmount
    }
  }
});
