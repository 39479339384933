
import { defineComponent, onMounted, onBeforeMount } from "vue";
import ToolbarFilter from "@/components/toolbar/ToolbarFilter.vue";
import ToolbarActions from "@/components/toolbar/ToolbarActions.vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import useComponentv2 from '@/composables/v2/useComponent';
import Table from '@/components/tables/v2/Table.vue';
import ModalFlow from "@/components/modals/default/v2/ModalShipmentLinesFlow.vue";
import Modal from "@/components/modals/default/v2/ModalTablePackages.vue";
import Icon from '@/components/icons/Icon.vue';

export default defineComponent({
  name: '',
  components: {
    ToolbarFilter,
    ToolbarActions,
    Table,
    Icon,
    ModalFlow,
    Modal,
  },
    props: {
      currentId: String
    },
    setup (props, { emit }) {
      const { initComponent, componentData, componentRegisterId, componentLoading, updateComponent, submitButtonComponent, submitLoadingComponent, saveComponent, componentStatus, reloadComponent, test, renderModal, renderAmount, renderId, reloadAmount } = useComponentv2();

      initComponent({
        componentName: "ShipmentsShipmentLinesPackagesTable", 
        displayMethod: "existing", /* new or existing */
        componentType: "table", /* form or table */
        componentSettings: { expandable: true },
        componentTypeActions: { init: true, actions: [
                        { type: "delete", label: "Delete", icon: { name: "Delete", color: 'danger' }, visibility: { state: false, maxSelected: null }, dispatch: { endpoint: "", reload: false, modal: { init: false, componentName: "", displayMethod: "", componentType: "" }, payloadConfig: { linesKey: "sale_lines", idKey: "return_id" } } },
                        { type: "createLabel", label: "Create Label", icon: { name: "Label", color: 'primary' }, visibility: { state: false, maxSelected: null }, dispatch: { endpoint: "LABEL_CREATE", reload: true, modal: { init: true, componentName: "ShipmentsShipmentLinesPreparedActionsCreateLabel", displayMethod: "new", componentType: "form" }, payloadConfig: [ { key: 'shipment_id', value: props.currentId }, { key: 'label_type', value: 'forward'}, { key: "packages", value: [], type: 'selection'} ] } },
                        // { type: "addUnpreparedLine", label: "Add Item ", icon: { name: "Add", color: 'primary' }, visibility: { state: true, maxSelected: 1 }, dispatch: { endpoint: "LABEL_CREATE", reload: true, modal: { init: true, componentName: "ShipmentsShipmentLinesPreparedActionsCreateLabel", displayMethod: "new", componentType: "form" }, payloadConfig: [ { key: 'package_id', type: 'selection' } ] } },  
                      ]},
        dispatchActions: { init: "PACKAGES", save: "PACKAGE_EDIT", params: { init: false, key: "", value: ""} },
        componentReload: true,
        componentRedirect: { init: false, data: "", path: ""},
        currentData: "allPackages",
        componentFilter: { init: false, get: "" },
        componentToolbar: { init: true, filter: false, actions: { init: true, title: "Packages", menu: [ { label: "Create", target: "#es_modal_shipments_shipment_lines_prepared_create" } ] } },
        currentId: props.currentId
      });

      onMounted(() => {
        MenuComponent.reinitialization();
      })

    return {
      componentData,
      componentRegisterId,
      componentLoading,
      componentStatus,
      updateComponent,
      submitButtonComponent,
      submitLoadingComponent,
      saveComponent,
      reloadComponent,
      test,
      renderModal,
      renderId,
      renderAmount,
      reloadAmount
    }
  }
});
