
import { ref, defineComponent, computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useContentStore } from "@/stores/content";
import ShipmentsShipmentLinesLines from '@/components/ShipmentsShipmentLinesLines.vue';
import ShipmentsShipmentLinesPackages from '@/components/ShipmentsShipmentLinesPackages.vue';
import useInit from '@/composables/v2/useInit';

export default defineComponent({
  components: {
    ShipmentsShipmentLinesLines,
    ShipmentsShipmentLinesPackages,
  },
  setup() {
    const route = useRoute();
    const { initTemplate, listenTo, componentList, reload } = useInit()

    initTemplate([
      { name: 'ShipmentsShipmentLinesLines', settings: { listenTo: { type: 'specific', components: ['ShipmentsShipmentLinesPackages'] } } },
      // { name: 'ShipmentsShipmentLinesPackages', settings: { listenTo: { type: 'specific', components: ['ShipmentsShipmentLinesLines'] } } },
    ])

    const currentId = computed(() => {
      return route.params.uid
    });

    return {
      currentId,
      reload,
      componentList,
      listenTo
    }
  }
});
